.accordion_container {
    outline: none;
  
    .accordion_item_line {
      display: flex;
      align-items: center;
      cursor: default;
      outline: none;
    }
  
    .accordion_item_icon {
      margin: 0px 15px;
    }
  
    .accordion_item_title {
      display: flex;
      align-items: center;
      flex: 1;
    }
  
    .accordion_item_ani {
      width: 1.2rem;
      height: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.6;
      transition: transform 0.2s linear;
      -webkit-transition: -webkit-transform 0.2s linear;
    }
  
    .accordion_item_inner {
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
    }
  
    .accordion_item_content {
      opacity: 0;
      transform: translateY(-1rem);
      transition: all 0.5s ease;
      -webkit-transform: translateY(-1rem);
      -webkit-transition: all 0.5s ease;
    }
  
    &.accordion_item_opened {
      .accordion_item_ani {
        &_180 {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
        &_90 {
          transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
        }
      }
  
      .accordion_item_inner {
        max-height: 1500rem;
        padding: 5px 0px;
      }
  
      .accordion_item_content {
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: translateY(0);
      }
    }
  }
  