@import '../../styles/index.scss';

.page_wrapper {
  height: 100vh;
}
.wrapper {
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-dark;

  h2 {
    text-align: center;
  }

  form {
    width: 40%;
    min-width: 300px;
    max-width: 400px;
    padding: 1rem;

    .field {
      padding: 1rem 0rem;
    }
    .login {
      color: $gray-light;
      font-weight: 600;
      .sign_in {
        color: $primary-color;
      }
    }
    .sent {
      text-align: center;
      font-size: 1.2rem;
      padding-bottom: 3rem;
      color: $gray-light;
    }
  }
}
